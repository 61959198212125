.container {
    cursor: pointer;
    position: relative; /* Add this for positioning the dropdown */
}

.selectedLanguage {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 10px 12px;
    padding-top: 0px;
    border-radius: 8px;
    justify-content: center;
    z-index: 10;
    position: relative;
    background-color: #F0F3FF;
    width: 97px;
}
  
.text {
    color: #2E3767;
    font-family: Syne_Regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.dropdown {
    padding-top: 20px;
    position: absolute;
    top: 10px;
    left: 0;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 5;
    width: 97px;
    border-radius: 12px;
}

.languageOption {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 12px;
    cursor: pointer;
}

.languageOption:first-child {
    padding-top: 14px;
}
  
.languageOption:hover {
    background-color: #f0f0f0;
}

.languageOption:last-child:hover {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}
  
@media only screen and (max-width: 500px) {
    .container {
        padding: 0;
    }
}
  