@font-face {
    font-family: Inter_Regular;
    src: url('../assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
    font-family: Inter_Bold;
    src: url('../assets/fonts/Inter/Inter-Bold.ttf');
}

@font-face {
    font-family: Syne_Regular;
    src: url('../assets/fonts/Syne/Syne-Regular.ttf');
}

@font-face {
    font-family: Syne_Bold;
    src: url('../assets/fonts/Syne/Syne-Bold.ttf');
}

body {
    width: 100% !important;
}

.font-syne-bold {
    font-family: Syne_Bold !important;
}

.font-syne-regular {
    font-family: Syne_Regular !important;
}

.font-inter-bold {
    font-family: Inter_Bold !important;
}

.font-inter-regular {
    font-family: Inter_Regular !important;
}

input {
    font-family: Inter_Regular !important;
}

legend {
    font-family: Inter_Regular !important;
}

label {
    font-family: Inter_Regular !important;
}

.fs-italic {
    font-style: italic;
}

.text-center {
    text-align: center;
}

.d-flex {
    display: flex;
}

.btn {
    cursor: pointer;
    padding: 1rem 1.5rem;
    background-color: #1A94F1;
    color: #FFFFFF;
    font-family: Syne_Bold;
    font-size: 1rem;
    border: none;
    box-shadow: none;
    border-radius: 40px;
}

.btn-big {
    cursor: pointer;
    padding: 20.25px 40.5px;
    background-color: #1A94F1;
    color: #FFFFFF;
    font-family: Syne_Bold;
    font-size: 27px;
    border: none;
    box-shadow: none;
    border-radius: 81px;
    width: fit-content;
}

.btn-secondary {
    cursor: pointer;
    padding: 1rem 1.5rem;
    background-color: transparent;
    color: #1A94F1;
    font-family: Syne_Bold;
    font-size: 1rem;
    border: 2px solid #1A94F1;
    box-shadow: none;
    border-radius: 40px;
    width: fit-content;
}

html, body {
    background-color: #F0F3FF;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 80px;
    position: relative;
    z-index: 1;
    max-width: 1024px;
    margin: 0 auto;
    width: 100%;
}

.footer-container {
    display: flex;
    gap: 16px;
}

.footer-text {
    text-align: center;
    font-family: Inter_Regular;
    font-size: 0.75rem;
    color: #7984A9;
    margin: 0;
}

.opacity-50 {
    opacity: 0.5;
}

.green-text-details {
    color: #26A749;
}

.gradient-yellow {
    background: linear-gradient(90deg, #FFC758 32.5%, #F98049 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.MuiPaper-rounded {
    border-radius: 32px !important;
    padding: 32px;
}

@media only screen and (max-width: 900px) {
    .btn-big {
        cursor: pointer;
        padding: 1rem 1.5rem;
        background-color: #1A94F1;
        color: #FFFFFF;
        font-family: Syne_Bold;
        font-size: 1rem;
        border: none;
        box-shadow: none;
        border-radius: 40px;
    }
}

@media only screen and (max-width: 750px) {
    .footer {
        flex-direction: column;
        gap: 16px;
        align-items: center;
        margin-top: 24px;
    }

    .footer-container {
        width: 100%;
        justify-content: center;
        gap: 16px;
        flex-wrap: wrap;
    }
}
