.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 64px;
}

.proceed_header {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 12px;
    padding: 0 2rem;
    margin-bottom: 40px;
}

.paragraph {
    color: #2E3767;
    font-size: 16px;
    font-family: Inter_Regular;
    margin: 0;
}

.title {
    color: #2E3767;
    font-size: 32px;
    font-family: Syne_Bold;
    margin: 0;
    text-align: center;
}

.slide_item {
    display: flex;
    width: 100%;
    padding: 36px 140.258px 0px 140.258px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background: #F9FAFF;
    box-shadow: 0px 20px 24px -4px rgba(0, 0, 0, 0.08), 0px 8px 8px -4px rgba(0, 0, 0, 0.03);
}

.arrows {
    position: absolute;
    display: flex;
    gap: 15px;
    bottom: -42px;
    right: 15px;
}

.arrow {
    top: 0 !important;
    position: relative !important;
    background: transparent !important;
    display: flex !important;
    padding: 12px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50px !important;
    border: 1px solid #0E62BE !important;
    width: 48px !important;
    height: 48px !important;
    opacity: 1 !important;
    transform: none !important;
}

.arrow_left {
    left: 0 !important;
}

.arrow_right {
    transform: rotate(180deg) !important;
    right: 0 !important;
}

.pagination {
    bottom: -19px !important;
    left: 15px !important;
    justify-content: flex-start !important;
    padding: 0 !important;
    gap: 8px !important;
}

.item_image {
    max-width: 100%;
}

.item_image_bottom {
    margin-bottom: 36px;
}

.goBack {
    display: flex;
    padding: 12px;
    align-self: flex-start;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #fff;
    background: #fff;
}

@media only screen and (max-width: 500px) {
    .container {
        padding: 12px 24px;
    }

    .proceed_header {
        padding: 0;
        margin-bottom: 16px;
        gap: 8px;
    }

    .paragraph {
        font-size: 18px;
    }
    
    .title {
        font-size: 32px;
    }

    .slide_item {
        padding: 16px 18px 15.075px 18px;
        border-radius: 12px;
    }

    .item_image_bottom {
        margin-bottom: 0px;
    }
}
