.splide__pagination .is-active {
    transform: none !important;
    background-color: #0E62BE !important;
    opacity: 1 !important;
}

.splide__pagination__page {
    opacity: 1 !important;
    background-color: #C3C9DB !important;
    margin: 0 !important;
}

.splide__slide {
    padding: 15px !important;
    padding-bottom: 30px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}
