input {
    border: none;
}

input:focus-visible {
    outline: none;
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
}

svg {
    cursor: pointer;
}

.container { 
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.label {
    font-family: Inter_Regular;
    font-size: 16px;
    color: #2E3767;
    margin: 0;
    line-height: 24px;
}

.labelError {
    font-family: Inter_Regular;
    font-size: 14px;
    color: #D41111;
    margin: 0;
    line-height: 24px;
}

.input {
    font-family: Inter_Regular;
    color: #2E3767;
    padding: 16px 20px;
    font-size: 1rem;
    border-radius: 6px;
    background-color: #F0F3FF;
}

.arrows {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    bottom: 0.825rem;
    right: 1rem;
}
