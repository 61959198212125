.container {
    padding: 0px 64px 24px 64px;
    overflow: hidden;
}

.content {
    padding: 32px 64px;
    border-radius: 32px;
    background: #F9FAFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
}

.slideInFromLeft {
    animation: slideInFromLeft 0.3s forwards;
}

.slideInFromRight {
    animation: slideInFromRight 0.3s forwards;
}

.slideOutToLeft {
    animation: slideOutToLeft 0.3s forwards;
}

.slideOutToRight {
    animation: slideOutToRight 0.3s forwards;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 48px;
}

.title {
    color: #2E3767;
    text-align: center;
    font-family: Syne_Bold;
    font-size: 48px;
    line-height: 120%;
    margin: 0;
}

.titleCookie {
    color: #2E3767;
    text-align: left;
    font-family: Syne_Bold;
    font-size: 48px;
    line-height: 120%;
    margin: 0;
}

.smallTitleCookie {
    color: #2E3767;
    text-align: left;
    font-family: Syne_Bold;
    font-size: 32px;
    line-height: 120%;
    margin: 20px 0px 0px 0px;
}

.description {
    color: #2E3767;
    text-align: center;
    font-family: Inter_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.descriptionCookie {
    color: #2E3767;
    text-align: left;
    font-family: Inter_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.linkCookie {
    color: #2E3767;
    text-align: left;
    font-family: Inter_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.listCookie {
    margin: 0;
    padding-left: 15px;
}

.headerContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 32px;
}

.goBack {
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #F0F3FF;
    background: #F0F3FF;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.progressText {
    color: #2E3767;
    font-family: Inter_Regular;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.progressBarContainer {
    height: 12px;
    border-radius: 4px;
    background: #F0F3FF;
    width: 100%;
}

.progressBar {
    height: 12px;
    border-radius: 4px;
    background: #58C322;
}

.formContainer {
    width: 100%;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
    margin: 0 auto;
}

.radioContainer {
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #F0F3FF;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 8px;
}

.radioContainer:last-child {
    margin-bottom: 0;
}

.info {
    color: #2E3767;
    text-align: center;
    font-family: Inter_Regular;
    font-size: 24px;
    font-style: italic;
    line-height: 120%;
    margin: 0;
}

.itemsContainer {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-self: flex-start;
}

.item {
    color: #2E3767;
    font-family: Inter_Regular;
    font-size: 16px;
    line-height: 200%;
}

.checkboxContainer {
    align-self: flex-start;
    display: flex;
    padding: 8px 24px;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #F0F3FF;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 8px;
}

.checkboxLabel {
    color: #2E3767;
    font-family: Inter_Regular;
    font-size: 18px;
    line-height: 24px;
}

.error {
    align-self: flex-start;
    margin: 0;
    margin-top: -20px;
    color: #D41111;
    font-family: Inter_Regular;
    font-size: 14px;
    line-height: 24px;
}

.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 6px;
    border: 1px solid #445188;
}

.inputRules {
    color: #2E3767;
    font-family: Inter_Regular;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 0px 24px 12px 24px;
    }

    .content {
        padding: 24px 24px;
    }

    .textContainer {
        margin-top: 16px;
        margin-bottom: 24px;
        width: 100%;
    }

    .title {
        font-size: 32px;
    }

    .description {
        font-size: 16px;
    }

    .info {
        font-size: 20px;
    }

    .formContainer {
        gap: 8px;
    }

    .goBack {
        padding: 5px;
    }

    .progressContainer {
        gap: 6px;
    }

    .headerContainer {
        gap: 16px;
    }

    .error {
        margin-top: 0;
    }
}
