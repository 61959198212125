.header {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.title {
    margin: 0;
    text-align: center;
    font-family: "Syne_Bold";
    font-size: 40px;
    line-height: 120%;
    color: #2E3767;
}

.subtitle {
    max-width: 33.75rem;
    margin: 0;
    text-align: center;
    font-family: "Inter_Regular";
    font-size: 1.125rem;
    line-height: 150%;
    color: #2E3767;
}

.container {
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
}

.itemsContainer {
    display: flex;
    justify-content: space-between;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 30%;
    justify-content: space-between;
}

.itemContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.itemTitle {
    width: 100%;
    margin: 0 0 1rem 0;
    text-align: center;
    font-family: "Syne_Bold";
    font-size: 2rem;
    line-height: 130%;
    color: #2E3767;
}

.imageContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.circle {
    background-color: #1A94F1;
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Syne_Regular";
    font-size: 2rem;
}

.text {
    font-family: "Inter_Regular";
    font-size: 1rem;
    margin: 0;
    color: #000;
    text-align: center;
    white-space: pre-line;
}

.image {
    width: 100%;
    height: 160px;
}

@media only screen and (max-width: 1250px) {
    .itemsContainer {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .item {
        width: 100%;
    }
}

@media only screen and (max-width: 850px) {
    .title {
        font-size: 32px;
    }
}
