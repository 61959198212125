.navbar {
    display: flex;
    padding: 24px 64px 16px 64px;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
    position: relative;
}

.actions {
    display: flex;
    gap: 16px;
}

.text {
    color: #445188;
    font-family: Syne_Regular;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-decoration-line: underline;
}

.logo_container {
    display: flex;
    gap: 16px;
    align-items: flex-start;
}

.and {
    font-family: Syne_Bold;
    font-size: 20px;
    line-height: 140%;
    color: #2E3767;
}

@media only screen and (max-width: 500px) {
    .navbar {
        display: flex;
        padding: 24px 24px 12px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        margin-bottom: 24px;
    }

    .actions {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .and {
        display: none;
    }

    .logo_container {
        flex-direction: column;
    }
}
