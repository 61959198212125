.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 1;
    max-width: 768px;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 100px;
    margin-top: 100px;
}

.welcomeImage {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: calc(100vh - 80px);
    max-width: 100%;
}

.title {
    color: #2E3767;
    text-align: center;
    font-family: Syne_Bold;
    font-size: 72px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 24px;
}

.headTitle {
    color: #2E3767;
    text-align: center;
    font-family: Syne_Bold;
    font-size: 32px;
    line-height: 130%;
    margin-top: 0;
    margin-bottom: 10px;
}

.description {
    color: #2E3767;
    text-align: center;
    font-family: Inter_Regular;
    font-size: 18px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 75px;
}

.welcomeFace {
    max-width: 100%;
    width: 70%;
    margin-bottom: 75px;
}

.infoButton {
    position: absolute;
    left: 0px;
    bottom: 0px;
}

.video {
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.modalContainer {
    background: rgba(46, 55, 103, 0.20);
    backdrop-filter: blur(28px);
}

.modalContentContainer {
    border-radius: 32px;
    border: 1px solid #C3C9DB;
    background: #FFF;
    padding: 32px;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.modalHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #2E3767;
    font-family: Syne_Bold !important;
    font-size: 24px !important;
    line-height: 125% !important;
    padding: 0 !important;
    margin-bottom: 24px !important;
}

@media only screen and (max-height: 1600px) {
    .welcomeImage {
        display: none;
    }

    .container {
        margin-top: 0px;
    }

    .description {
        margin-bottom: 32px;
    }
}

@media only screen and (max-width: 1000px) {
    .welcomeImage {
        display: none;
    }

    .container {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 900px) {
    .title {
        font-size: 40px;
    }

    .description {
        margin-bottom: 32px;
    }

    .headTitle {
        font-size: 28px;
    }

    .infoButton {
        position: relative;
        margin-top: 12px;
    }

    .container {
        padding: 0 24px;
        height: auto;
    }
}

@media only screen and (max-height: 1300px) {
    .infoButton {
        position: relative;
        margin-top: 12px;
    }
}

@media only screen and (max-height: 800px) {
    .container {
        height: auto;
    }

    .infoButton {
        position: relative;
        margin-top: 12px;
    }
}