.error_container {
    padding: 0 2rem;
}

.container {
    padding: 0px 64px;
    overflow: hidden;
}

.content {
    padding: 32px 64px;
    border-radius: 32px;
    background: #F9FAFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: transform 0.3s ease;
}

.textContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 32px;
    margin-bottom: 48px;
}

.title {
    color: #2E3767;
    text-align: center;
    font-family: Syne_Bold;
    font-size: 48px;
    line-height: 120%;
    margin: 0;
}

.description {
    color: #2E3767;
    text-align: center;
    font-family: Inter_Regular;
    font-size: 20px;
    line-height: 150%;
    margin: 0;
}

.top_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 24px;
}

.circle {
    position: relative;
    background-color: #6558f5;
    padding: 1rem;
    border-radius: 50%;
    width: 6rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: "Inter_Regular";
    font-size: 1.5rem;
}

.results {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    height: auto;
    min-height: 89.7vh;
}

.result_screen_export {
    display: flex;
    flex-direction: column;
    width: 1024px;
    height: auto;
}

.results_header {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 12px;
    padding: 0 2rem;
    margin-bottom: 40px;
}

.min_height_auto {
    min-height: auto;
}

.padding_h {
    padding: 0 24px !important;
}

.header_error {
    display: flex;
    padding: 32px !important;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border-radius: 24px;
    background-color: #fff;
}

.results_header_size {
    width: 100% !important;
    max-width: 850px;
}

.results_info {
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 35px;
    padding: 0 2rem;
}

.results_info_item {
    display: flex;
    width: 100%;
    gap: .5rem;
    align-items: center;
    justify-content: center;
}

.results_info_item_details {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 0rem;
}

.results_info_item_battery {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 15%;
}

.item_info {
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    background-color: #58C322;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 2rem;
    align-items: center;
    gap: .25rem;
}

.results_footer {
    width: 100% !important;
    background-color: #2E3767;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 48px 64px;
}

.results_footer_container {
    max-width: 850px;
    display: flex;
    align-items: flex-end;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    width: 100%;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    justify-content: space-between;
}

.results_footer_logo_info {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    gap: 2rem;
}

.preFooter {
    margin-top: 50px;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.preFooterTitle {
    max-width: 500px;
    color: #2E3767;
    font-size: 24px;
    font-family: Syne_Bold;
    margin: 0;
}

.preFooterContent {
    display: flex;
    align-items: center;
    gap: 40px;
}

.preFooterDescription {
    color: #2E3767;
    font-size: 14px;
    font-family: Inter_Regular;
    margin: 0;
}

.w_60 {
    width: 70% !important;
    align-items: flex-start !important;
}

.button_container {
    margin-top: 24px;
    margin-bottom: 24px;
    background-color: #F0F3FF !important;
    color: #2E3767 !important;
}

.paragraph {
    color: #2E3767;
    font-size: 20px;
    font-family: Inter_Regular;
    margin: 0;
}

.paragraph_small {
    color: #2E3767;
    font-size: 16px;
    font-family: Inter_Regular;
    margin: 0;
    max-width: 33rem;
}

.bottom_title {
    color: #2E3767;
    font-size: 28px;
    font-family: Syne_Bold;
    margin: 0;
    text-align: center;
}

.title_item {
    color: #2E3767;
    font-size: 27px;
    font-family: Syne_Bold;
    margin: 0;
}

.paragraphs_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.header_info {
    width: auto !important;
    display: flex;
    background-color: #7984A9;
    padding: 0.5rem 0.75rem 0.5rem 0.5rem;
    border-radius: 2rem;
    align-items: center;
    gap: .25rem;
}

.info_text {
    color: white;
    font-size: 14px;
    font-family: Inter_Regular;
    margin: 0;
}

.info_text_bottom {
    color: white;
    font-size: 12px;
    font-style: italic;
    font-family: Inter_Regular;
    margin: 0;
    color: #2E3767;
    text-align: left;
}

.table_title {
    text-align: left;
    color: #2E3767;
    font-size: 20px;
    font-family: Inter_Bold;
    margin: 0;
}

.table_title:last-child {
    padding-left: 15px;
}


.table_item {
    text-align: left;
    color: #2E3767;
    font-size: 20px;
    font-family: Inter_Regular;
    margin: 0;
}

.table_item:last-child {
    padding-left: 15px;
}

@media only screen and (max-width: 820px) {
    .preFooter {
        align-items: center;
    }

    .preFooterTitle {
        text-align: center;
    }

    .preFooterContent {
        align-items: center;
        gap: 24px;
        flex-direction: column;
    }

    .preFooterDescription {
        text-align: center;
    }

    .results_footer_container {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 500px) {
    .error_container {
        padding: 0 1rem;
    }

    .w_60 {
        width: 100% !important;
        align-items: center;
    }

    .content {
        padding: 32px 16px;
    }

    .button_container {
        margin-top: 16px;
        margin-bottom: 0px;
    }

    .results_header {
        padding: 0 24px;
        margin-bottom: 24px;
    }

    .title {
        font-size: 32px;
    }

    .paragraph {
        font-size: 18px;
    }

    .results_info {
        padding: 0 24px;
    }

    .title_item {
        font-size: 27px;
    }

    .info_text {
        font-size: 14px;
    }

    .results_footer {
        padding: 24px 16px;
    }

    .results_info_item {
        flex-direction: column;
        gap: 1.5rem;
    }

    .results_info_item_details {
        width: 100%;
    }

    .results_info_item_battery {
        width: 100%;
        align-items: center;
    }

    .results_footer_logo_info {
        width: 95%;
    }

    .info_text_bottom {
        font-size: 12px;
    }
}
